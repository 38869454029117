import React from "react";


export const H2 = ({
    children
}) => {
    return (
        <h2 className="text-3xl text-gray-900 sm:text-4xl font-heading">{children}</h2>
    )
}


const Intro = ({
    title,
    text
}) => {

    return (
        <div className="relative pt-16 pb-10 px-4 sm:px-6 lg:pt-24 lg:pb-12 lg:px-8">
            <div className="relative max-w-7xl mx-auto">
                <div className="text-center">
                    <H2>{title}</H2>
                    <p className="mt-3 max-w-2xl mx-auto text-xl sm:mt-4 font-light">
                        {text}
                    </p>
                </div>
            </div>
        </div>
    )
}


export default Intro;