import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CarouselItemWrapper from './CarouselItemWrapper';
import TextIntro from './Headings/TextIntro';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'

import Logo from './Logo';
import SlickCarousel from './SlickCarousel';
import Intro from '../Intro';
import BlogPreviewItem from './BlogPreviewItem';

const { compile } = require('html-to-text');

// function BlogPreviewItem({
//     cat,
//     image,
//     slug,
//     title,
//     excerpt,
//     tagNodes,
//     date,
//     readTime
// }) {
//     return (
//         <div className="grid justify-center items-start bg-white opacity-80 transition-opacity duration-500 opacity-100 ease-in y hover:opacity-100">
//             <a className='flex flex-col flex-1' href={`blog/${slug}`} title={title}>
//                 <div className="flex-shrink-0">
//                     <GatsbyImage
//                         imgClassName={"h-48 w-full object-cover aspect-square"}
//                         className={"aspect-square"}
//                         image={image}
//                         alt={title}
//                     />
//                 </div>

//                 <div className="flex-1 p-6 flex sm:px-4 px-8 flex-col justify-between">
//                     <div className="flex-1">
//                         <div className="block mt-2">
//                             <h4 className="text-xl text-center font-light text-gray-900">{title}</h4>
//                             {/* <div
//                                 className="mt-3 text-base text-gray-500 line-clamp-3 text-justify hyphens-auto"
//                                 dangerouslySetInnerHTML={{
//                                     __html: excerpt
//                                 }} /> */}

//                         </div>
//                     </div>
//                     {/* <div className="mt-6 flex items-center justify-self-end">
//                         <div>
//                             <div className="relative h-10 w-10 rounded-full bg-theme p-2 flex justify-center items-center">
//                                 <Logo
//                                     className="sm:h-2"
//                                     link={false}
//                                 />
//                             </div>
//                         </div>
//                         <div className="ml-3">
//                             <p className="text-sm font-medium text-gray-900">
//                                 viwiv GmbH
//                             </p>
//                             <div className="flex space-x-1 text-sm text-gray-500">
//                                 <time>{date}</time>
//                                 <span aria-hidden="true">&middot;</span>
//                                 <span>Lesezeit: {readTime} Min</span>
//                             </div>
//                         </div>
//                     </div> */}
//                 </div>

//             </a>
//         </div>
//     );
// }

const BlogFilter = ({

    criteria = [
        {
            label: 'Blog',
            href: '#',
            active: false
        },
        {
            label: 'Bild',
            href: '#',
            active: false
        },
        {
            label: 'Video',
            href: '#',
            active: false
        },
        {
            label: 'Text',
            href: '#',
            active: false
        }
    ]

}) => {
    return (
        <div>
            <ul
                className='flex mt-4 justify-center items-center space-x-12 text-lg'
            >
                {criteria.map(({ label, href, active }, index) => (
                    <li key={index}>
                        <a
                            href={href}
                            title={label}
                            className='flex font-heading cursor-pointer p-4'
                        >
                            {label}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const Pagination = ({

}) => {
    return (
        <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div className="-mt-px w-0 flex-1 flex">
                <a
                    href="#"
                    className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                >
                    <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Previous
                </a>
            </div>
            <div className="hidden md:-mt-px md:flex">
                <a
                    href="#"
                    className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                >
                    1
                </a>
                {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
                <a
                    href="#"
                    className="border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                    aria-current="page"
                >
                    2
                </a>
                <a
                    href="#"
                    className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                >
                    3
                </a>
                <span className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                    ...
                </span>
                <a
                    href="#"
                    className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                >
                    8
                </a>
                <a
                    href="#"
                    className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                >
                    9
                </a>
                <a
                    href="#"
                    className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                >
                    10
                </a>
            </div>
            <div className="-mt-px w-0 flex-1 flex justify-end">
                <a
                    href="#"
                    className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                >
                    Next
                    <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </a>
            </div>
        </nav>
    )
}

// https://codepen.io/kevinpowell/pen/ExbKBQa

const BlogArchive = () => {
    return (
        <div className='mb-36'>
            <div className="relative px-4 py-6 sm:px-6 bg-white">
                <Intro
                    title={"Was bisher geschah."}
                    text={"Hier findest du eine Auswahl an Laila-schreibt-Projekten: Publireportagen, Blogbeiträge, Foto-Reportagen, Recap-Videos und Kommunikations-Mandate."}
                />
                <div className="absolute inset-0">
                    <div className="h-1/3 sm:h-2/3" />
                </div>
            </div>
            <div className="relative">
                <div className="mt-12 w-full">
                    <StaticQuery
                        query={componentQuery}
                        render={data => {
                            const {
                                allWpPost: { edges }
                            } = data

                            return (

                                <div className='container--preview grid md:grid-cols-2 lg:grid-cols-3 gap-24'>
                                    {
                                        edges.map(({
                                            node: {
                                                title,
                                                excerpt,
                                                content,
                                                date,
                                                slug,
                                                categories: { nodes: catNodes },
                                                tags: { nodes: tagNodes },
                                                featuredImage
                                            }
                                        }) => {
                                            const image = featuredImage && featuredImage.node.localFile.childImageSharp.gatsbyImageData
                                            const cat = catNodes[0].name
                                            const convert = compile();
                                            const readTime = Math.round(convert(content).split(' ').length / 200)
                                            return (

                                                <BlogPreviewItem
                                                    key={slug}
                                                    slug={slug}
                                                    image={image}
                                                    cat={cat}
                                                    readTime={readTime}
                                                    date={date}
                                                    excerpt={excerpt}
                                                    tagNode={tagNodes}
                                                    title={title}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            )
                        }}
                    />
                </div>
                {/* 
                TODO: pagination
                <Pagination />
                 */}
            </div>
        </div>
    )
}

export default BlogArchive;

export const componentQuery = graphql`
query BlogArchive {
    allWpPost(sort: { fields: date, order: DESC }) {
        edges {
            node {
                title
                excerpt
                content
                slug
                tags {
                    nodes {
                    name
                    }
                }
                categories {
                    nodes {
                    name
                    }
                }
                date(formatString: "DD. MMMM YYYY")
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 800, 
                                    height: 800,
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}
`