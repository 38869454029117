import '../App.css';
import React from "react";
import { useRef, useEffect } from "react";

import BlogPreview from '../components/BlogPreview';

import Seo from '../components/seo';
import ProjektePreview from '../components/ProjektePreview';
import ImageHero from '../components/ImageHero';
import Section2Col from '../components/Section2Col';

import uebermich1 from '../images/ueber-mich-1.png';
import uebermich2 from '../images/ueber-mich-2.png';
import uebermich3 from '../images/ueber-mich-3.png';
import uebermich4 from '../images/ueber-mich-4.png';
import BlogArchive from '../components/BlogArchive';
import TwoColCTA from '../components/TwoColCTA';



// https://play.tailwindcss.com/lQk1tAEWet

const Section = Section2Col


const IndexPage = () => {

    const ref = useRef(null);

    return (
        <div>
            <Seo title='Projekte - laila schreibt' />
            <BlogArchive />

            <TwoColCTA
                title='Like what you see?'
                text="Dann lass uns dein Projekt bei einem Kaffee besprechen (oder Aperol, je nach Tageszeit)."
                btnLabel='Hier anklopfen'
            />
        </div>
    )
}

export default IndexPage;
