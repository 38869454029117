import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CarouselItemWrapper from './CarouselItemWrapper';

import Logo from './Logo';
import SlickCarousel from './SlickCarousel';

const { compile } = require('html-to-text');




function BlogPreviewItem({
    cat,
    image,
    slug,
    title,
    excerpt,
    tagNodes,
    date,
    readTime
}) {
    return (
        <div className="grid sm:py-4 pb-8 mb-10 justify-center items-start shadow-lg bg-white transition-opacity duration-700 opacity-100 ease-in hover:opacity-80">
            <a className='flex flex-col flex-1' href={`/blog/${slug}`} title={title}>
                <div className="flex-shrink-0">
                    <GatsbyImage
                        imgClassName={"h-48 w-full object-cover"}
                        className={""}
                        image={image}
                        alt={title}
                    />
                </div>

                <div className="flex-1 p-6 flex sm:px-4 px-8 flex-col justify-between">
                    <div className="flex-1">
                        <div className="block mt-2">
                            <h4 className="text-xl text-gray-900">{title}</h4>
                            <div
                                className="mt-3 text-base text-gray-500 line-clamp-3 text-justify hyphens-auto"
                                dangerouslySetInnerHTML={{
                                    __html: excerpt
                                }} />

                        </div>
                    </div>
                    <div className="mt-6 flex items-center justify-self-end">
                        <div>
                            <div className="relative h-10 w-10 rounded-full bg-theme p-2 flex justify-center items-center">
                                <Logo
                                    className="sm:h-2"
                                    link={false}
                                />
                            </div>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-gray-900">
                                Laila schreibt
                            </p>
                            <div className="flex space-x-1 text-sm text-gray-500">
                                <time>{date}</time>
                                <span aria-hidden="true">&middot;</span>
                                <span>Lesezeit: {readTime} Min</span>
                            </div>
                        </div>
                    </div>
                </div>

            </a>
        </div>
    );
}

// https://codepen.io/kevinpowell/pen/ExbKBQa

const BlogPreview = () => {
    return (
        // px-4 lg:px-8 
        <div className="relative px-4 py-6 sm:px-6  bg-white">
            <div className="absolute inset-0">
                <div className="h-1/3 sm:h-2/3" />
            </div>
            <div className="relative">
                {/* <TextIntro
                    title="viwiv Blog"
                >
                    <p className="pb-4">
                        Hier berichten wir über Themen rund um Data Science und Machine Learning. Du kannst hier auch mehr über aktuelle Themen und Trends erfahren.
                    </p>
                </TextIntro> */}
                <div className="mt-12 w-full">
                    <StaticQuery
                        query={componentQuery}
                        render={data => {
                            const {
                                allWpPost: { edges }
                            } = data

                            return (

                                <SlickCarousel>
                                    {
                                        edges.map(({
                                            node: {
                                                title,
                                                excerpt,
                                                content,
                                                date,
                                                slug,
                                                categories: { nodes: catNodes },
                                                tags: { nodes: tagNodes },
                                                featuredImage
                                            }
                                        }) => {
                                            const image = featuredImage && featuredImage.node.localFile.childImageSharp.gatsbyImageData
                                            const cat = catNodes[0].name
                                            const convert = compile();
                                            const readTime = Math.round(convert(content).split(' ').length / 200)

                                            return (
                                                <CarouselItemWrapper
                                                    key={slug}
                                                >
                                                    <BlogPreviewItem
                                                        key={slug}
                                                        slug={slug}
                                                        image={image}
                                                        cat={cat}
                                                        readTime={readTime}
                                                        date={date}
                                                        excerpt={excerpt}
                                                        tagNode={tagNodes}
                                                        title={title}
                                                    />
                                                </CarouselItemWrapper>
                                            )
                                        })
                                    }
                                </SlickCarousel>
                            )
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default BlogPreview;

export const componentQuery = graphql`
    query BlogPreview {
        allWpPost(sort: { fields: date, order: DESC }) {
            edges {
                node {
                    title
                    excerpt
                    content
                    slug
                    tags {
                        nodes {
                        name
                        }
                    }
                    categories {
                        nodes {
                        name
                        }
                    }
                    date(formatString: "DD. MMMM YYYY")
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 800, 
                                        height: 800,
                                        placeholder: BLURRED
                                    )                              
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`